var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "attachment-availability-wrapper",
    [
      _c("h2", [_vm._v(_vm._s(this.title))]),
      _c(
        "app-form",
        {
          attrs: {
            id: "attachment-form",
            submitAction: _vm.edit,
            submitSuccessAction: _vm.editSuccess,
            validationOptions: _vm.validationOptions
          }
        },
        [
          _c(
            "div",
            { staticClass: "form-group" },
            [
              _c("app-dropdownlist", {
                attrs: {
                  id: "attachmentTypeId",
                  enabled: false,
                  placeholder: _vm.$t("attachments.type"),
                  dataSource: _vm.attachmentTypes,
                  fields: { text: "name", value: "id" }
                },
                model: {
                  value: _vm.attachment.attachmentTypeId,
                  callback: function($$v) {
                    _vm.$set(_vm.attachment, "attachmentTypeId", $$v)
                  },
                  expression: "attachment.attachmentTypeId"
                }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "form-group" },
            [
              _c("app-textbox", {
                attrs: { id: "name", placeholder: _vm.$t("shared.name") },
                model: {
                  value: _vm.attachment.name,
                  callback: function($$v) {
                    _vm.$set(_vm.attachment, "name", $$v)
                  },
                  expression: "attachment.name"
                }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "form-group" },
            [
              _c("app-textbox", {
                attrs: {
                  id: "fileName",
                  enabled: false,
                  placeholder: _vm.$t("shared.fileName")
                },
                model: {
                  value: _vm.attachment.fileName,
                  callback: function($$v) {
                    _vm.$set(_vm.attachment, "fileName", $$v)
                  },
                  expression: "attachment.fileName"
                }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "form-group" },
            [
              _c("div", { staticClass: "label-text" }, [
                _vm._v(_vm._s(_vm.$t("attachments.file")))
              ]),
              _c("ejs-uploader", {
                attrs: {
                  id: "attachmentUploader",
                  maxFileSize: "20000000",
                  multiple: false,
                  selected: _vm.attachmentSelected
                }
              })
            ],
            1
          ),
          _c("app-form-buttons", {
            attrs: {
              addSubmitButton: true,
              submitButtonText: "shared.edit",
              addCancelButton: true,
              cancelAction: _vm.cancel
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }