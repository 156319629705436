var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm.isAvailable == undefined
    ? _c("div", [_c("p", [_vm._v("Verifica disponibilità servizio...")])])
    : _vm.isAvailable
    ? _c("div", [_vm._t("default")], 2)
    : _c("div", [
        _c("h2", [_vm._v("Allegati non disponibili")]),
        _c("p", [
          _vm._v("Il servizio degli allegati non è al momento disponibile.")
        ]),
        _c("p", [
          _vm._v("Se il problema persiste, contattare lo sviluppatore.")
        ])
      ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }